import { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";

const AuthContext = createContext();
export const AuthData = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [isAuth, setIsAuth] = useState(false);

  useEffect(() => {
    axios
      .get("/api/authStatus", {
        withCredentials: true,
      })
      .then((response) => {
        setIsAuth(response.data.isAuth);
      })
      .catch(() => {
        setIsAuth(false);
      });
  }, []);

  useEffect(() => {
    console.log(isAuth);
  }, [isAuth]);

  const login = async (username, password) => {
    return await axios
      .post(
        "/api/login",
        { username: username, password: password },
        { withCredentials: true }
      )
      .then((response) => {
        console.log(response)
        if (response.status === 200) {
          setIsAuth(true);
          return true;
        }
      })
      .catch(() => {
        return false;
      });
  };

  const logout = () => {
    axios
      .get("/api/logout", {
        withCredentials: true,
      })
      .then((response) => {
        if (response.status === 200) {
          setIsAuth(false);
        }
      });
  };

  return (
    <AuthContext.Provider value={{ isAuth, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
