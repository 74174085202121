import React from "react";
import Runko from "./Runko";

const Etusivu = () => {
  return (
    <div>
      <Runko />
    </div>
  );
};

export default Etusivu;
